var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"id":"brand-nearby"}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full lg:w-8/12 mb-base"},[_c('div',{staticClass:"vx-row"},_vm._l((_vm.place_categories),function(place_category,placeCategoryIndex){return _c('div',{key:placeCategoryIndex,class:[
            'vx-col w-full',
            placeCategoryIndex !== _vm.place_categories.length - 1
              ? 'mb-base'
              : ''
          ]},[_c('h4',{staticClass:"text-xl font-semibold mb-4"},[_vm._v(" "+_vm._s(place_category.name)+" ")]),_c('div',{staticClass:"vx-card p-5"},[_c('div',{staticClass:"vx-row"},_vm._l((place_category.places),function(place,placeIndex){return _c('div',{key:placeIndex,staticClass:"vx-col w-full"},[_c('h5',{staticClass:"text-base font-medium mb-3"},[_vm._v(_vm._s(place.name))]),_vm._l((place.brand_nearbies),function(brand_nearby,brandNearbyIndex){return _c('div',{key:brandNearbyIndex,staticClass:"vx-row items-end select-none mb-3"},[_c('div',{staticClass:"vx-col w-full md:w-5/12"},[_c('vs-input',{staticClass:"w-full",attrs:{"label":"Name (*)"},model:{value:(brand_nearby.name),callback:function ($$v) {_vm.$set(brand_nearby, "name", $$v)},expression:"brand_nearby.name"}})],1),_c('div',{staticClass:"vx-col w-full md:w-5/12"},[_c('vs-input',{staticClass:"w-full",attrs:{"type":"number","label":"Distance (*)"},model:{value:(brand_nearby.distance),callback:function ($$v) {_vm.$set(brand_nearby, "distance", $$v)},expression:"brand_nearby.distance"}})],1),_c('div',{staticClass:"vx-col w-full md:w-2/12 flex items-center cursor-pointer mb-2"},[_c('vs-icon',{attrs:{"icon":"delete","color":"danger"}}),_c('span',{staticClass:"text-danger ml-1",on:{"click":function($event){return _vm.deletePlace(
                          placeCategoryIndex,
                          placeIndex,
                          brandNearbyIndex
                        )}}},[_vm._v("Delete")])],1)])}),_c('div',{staticClass:"vx-row mt-3"},[_c('div',{staticClass:"vx-col w-full flex items-center"},[_c('vs-icon',{attrs:{"icon":"add","color":"primary"}}),_c('span',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){return _vm.addPlace(placeCategoryIndex, placeIndex)}}},[_vm._v("Add another")])],1)]),_c('vs-divider')],2)}),0)])])}),0)]),_vm._m(0)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-button',{attrs:{"icon":"save"},on:{"click":_vm.storeData}},[_vm._v("Save")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col w-full lg:w-4/12 mt-8"},[_c('h4',{staticClass:"text-lg font-semibold mb-3 capitalize"},[_vm._v(" Tell us what nearby ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum quia, iure distinctio, nam nisi molestiae laboriosam expedita vero explicabo reiciendis doloremque. ")]),_c('p',[_vm._v("Quo non blanditiis ducimus minima veniam error sapiente commodi?")])])
}]

export { render, staticRenderFns }