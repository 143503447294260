<template>
  <div id="brand-nearby" v-show="!loading">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-8/12 mb-base">
        <div class="vx-row">
          <div
            :class="[
              'vx-col w-full',
              placeCategoryIndex !== place_categories.length - 1
                ? 'mb-base'
                : ''
            ]"
            v-for="(place_category, placeCategoryIndex) in place_categories"
            :key="placeCategoryIndex"
          >
            <h4 class="text-xl font-semibold mb-4">
              {{ place_category.name }}
            </h4>
            <div class="vx-card p-5">
              <div class="vx-row">
                <!-- Place name -->
                <div
                  class="vx-col w-full"
                  v-for="(place, placeIndex) in place_category.places"
                  :key="placeIndex"
                >
                  <h5 class="text-base font-medium mb-3">{{ place.name }}</h5>
                  <div
                    class="vx-row items-end select-none mb-3"
                    v-for="(brand_nearby,
                    brandNearbyIndex) in place.brand_nearbies"
                    :key="brandNearbyIndex"
                  >
                    <div class="vx-col w-full md:w-5/12">
                      <vs-input
                        class="w-full"
                        label="Name (*)"
                        v-model="brand_nearby.name"
                      />
                    </div>

                    <div class="vx-col w-full md:w-5/12">
                      <vs-input
                        type="number"
                        class="w-full"
                        label="Distance (*)"
                        v-model="brand_nearby.distance"
                      />
                    </div>

                    <div
                      class="vx-col w-full md:w-2/12 flex items-center cursor-pointer mb-2"
                    >
                      <vs-icon icon="delete" color="danger"></vs-icon>
                      <span
                        class="text-danger ml-1"
                        @click="
                          deletePlace(
                            placeCategoryIndex,
                            placeIndex,
                            brandNearbyIndex
                          )
                        "
                        >Delete</span
                      >
                    </div>
                  </div>

                  <div class="vx-row mt-3">
                    <div class="vx-col w-full flex items-center">
                      <vs-icon icon="add" color="primary"></vs-icon>
                      <span
                        class="text-primary cursor-pointer"
                        @click="addPlace(placeCategoryIndex, placeIndex)"
                        >Add another</span
                      >
                    </div>
                  </div>

                  <vs-divider></vs-divider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-col w-full lg:w-4/12 mt-8">
        <h4 class="text-lg font-semibold mb-3 capitalize">
          Tell us what nearby
        </h4>
        <p class="mb-2">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum quia,
          iure distinctio, nam nisi molestiae laboriosam expedita vero explicabo
          reiciendis doloremque.
        </p>
        <p>Quo non blanditiis ducimus minima veniam error sapiente commodi?</p>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button icon="save" @click="storeData">Save</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "BrandNearbyIndex",

  metaInfo: {
    title: "Brand Nearby",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  data() {
    return {
      place_categories: [],

      loading: true
    };
  },

  methods: {
    addPlace(categoryIndex, placeIndex) {
      this.place_categories[categoryIndex].places[
        placeIndex
      ].brand_nearbies.push({ name: "", distance: 0 });
    },

    deletePlace(categoryIndex, placeIndex, brandNearbyIndex) {
      this.place_categories[categoryIndex].places[
        placeIndex
      ].brand_nearbies.splice(brandNearbyIndex, 1);
    },

    getData() {
      this.$vs.loading({
        type: 'sound'
      })
      
      axios
        .get(`vancynet/brand-nearby`)
        .then(({ data: res }) => {
          this.place_categories = res.data;
        })
        .catch(err => this.$catchErrorResponse(err))
        .finally(() => {
          this.loading = false
          this.$vs.loading.close()
        })
    },

    storeData() {
      let places = [];
      this.place_categories.map(category =>
        category.places.map(place => places.push(place))
      );

      axios
        .post(`vancynet/brand-nearby`, { places })
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Success",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.getData()
        })
        .catch(err => this.$catchErrorResponse(err));
    }
  },

  created() {
    this.getData();
  }
};
</script>

<style></style>
